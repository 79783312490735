import React, { Component, Fragment } from 'react';
import Api from '../../../api/api'
import { connect } from 'react-redux';
import { Col, Button } from 'reactstrap'
import { FaPlus, FaFileDownload, FaFile } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import { PageTitle, DataTable } from '../../../components/';
import { getCompaniesAction, deleteCompanyAction } from '../../../actions/companyAction'
import { getEmployeesAction } from '../../../actions/employeesAction'
import { getDocumentsAction } from '../../../actions/documentAction'
import { schema } from '../../../utils/forms/company'
import { alphanumInsensitiveSort } from '../../../utils/'

function perc2color(perc, min, max) {
    perc = (100 - perc);
    var base = (max - min);

    if (base == 0) { perc = 100; }
    else {
        perc = (perc - min) / base * 100;
    }
    var r, g, b = 0;
    if (perc < 50) {
        r = 255;
        g = Math.round(5.1 * perc);
    }
    else {
        g = 220;
        r = Math.round(510 - 5.10 * perc);
    }
    var h = r * 0x10000 + g * 0x100 + b * 0x1;
    return '#' + ('000000' + h.toString(16)).slice(-6);
}

const RapidAction = () => (
    <Col md="3" className="pr-5 d-flex flex-column justify-content-start align-items-end">
        <h4 className="mt-2 mb-3 mr-2 text-black">
            Azioni rapide
                                        </h4>
        <Link to='/company/add'>
            <Button className="new-expiration-button py-1 pl-1 pr-3 shadow mb-2 d-flex text-left align-items-center">
                <div className="mr-3">
                    <FaPlus className="hoverable-icon static brand p-2"></FaPlus>
                </div>
                <div className="text-black">
                    Inserisci nuova azienda
                </div>
            </Button>
        </Link>
        <Button disabled={true} className="new-expiration-button py-1 pl-1 pr-3 shadow mb-2 d-flex text-left align-items-center">
            <div className="mr-3">
                <FaFileDownload className="hoverable-icon static brand p-2"></FaFileDownload>
            </div>
            <div className="text-black">
                Esporta tabella in .csv
                </div>
        </Button>
    </Col>
)

class CompanyList extends Component {

    constructor(props) {
        super(props);

        this.state = {
            data: [],
            sectors: {}
        }
    }

    renderCell = (field, value, row) => {
        switch (field) {
            case "id":
                return (
                    <td className="text-center text-muted p-md-3" name={field}>
                        {value}
                    </td>
                );

            case "shorthand":
            case "business_name":
                return (
                    <td className="text-center p-md-3 width-25" name={field}>
                        <a href={`/company/edit/${row.original.id}`}>{value}</a>
                    </td>
                );

            case "description":
                return null;

            case "documents":
                const color = perc2color(Number(value * 100 / 20), 50, 100)
                return (
                    <td className="text-center p-md-3">
                        <div className="badge badge-pill badge-primary" style={{ backgroundColor: color }}>{value}</div>
                    </td>
                );

            default:
                return (
                    <td className="text-center p-md-3" name={field}>
                        {value}
                    </td>
                );
        }
    };

    loadSectors = async () => {
        const sectors = await Api.getSectors()
        return sectors
    }

    async componentDidMount() {
        await this.props.getCompanies()
        await this.props.getEmployees()
        await this.props.getDocuments()
        const response = await this.loadSectors()
        const sectors = response.data

        if (sectors) {
            let parsedSectors = {}
            for (var i = 0; i < sectors.length; i++) {
                parsedSectors[sectors[i].id] = sectors[i].name;
            }
            console.log(parsedSectors, sectors)
            this.setState({ sectors: parsedSectors })
        }

    }


    handleDelete = id => {
        this.props.deleteCompany(id)
    }


    render() {

        const { companies } = this.props;
        const { sectors } = this.state;

        return (
            <Fragment>
                <PageTitle
                    heading="Aziende"
                    subheading="La lista di tutte le aziende inserite nel gestionale."
                    icon={false}
                    bread=""
                >
                    <RapidAction />
                </PageTitle>
                {companies &&
                    <DataTable
                        handleDelete={this.handleDelete}
                        defaultSorting={[{
                            id: 'business_name',
                            desc: false // not necessary unless set to true, but it's good to know its there.
                        }]}
                        schema={schema}
                        hasViewTooltip={true}
                        title="Cerca:"
                        columns={[
                            {
                                Header: 'id',
                                accessor: 'id'
                            },
                            {
                                Header: 'Rag. Soc.',
                                accessor: 'business_name',
                                sortType: alphanumInsensitiveSort
                            },
                            {
                                Header: 'Settore',
                                accessor: row => sectors[row.sector_id] ? sectors[row.sector_id] : "Nessun settore"
                            },
                            {
                                Header: 'Alias',
                                accessor: 'shorthand'
                            },
                            {
                                Header: 'Referente',
                                accessor: 'referrer_id'
                            },
                            {
                                Header: 'P. Iva',
                                accessor: 'vat_number'
                            },
                            {
                                Header: 'Dipendenti',
                                accessor: row => row.employees && row.employees.length || 0
                            },
                            {
                                Header: 'Scadenze',
                                accessor: row => row.documents && row.documents.length || 0
                            },
                        ]}
                        data={Object.values(companies)}
                        model={"company"}
                        renderCell={this.renderCell}
                    />
                }

            </Fragment>

        )
    }
}

const mapDispatchToProps = dispatch => ({
    getCompanies: () => dispatch(getCompaniesAction()),
    getEmployees: () => dispatch(getEmployeesAction()),
    getDocuments: () => dispatch(getDocumentsAction()),
    deleteCompany: id => dispatch(deleteCompanyAction(id))
})

const mapStateToProps = state => {
    const companies = state.companies.byId
    return { companies }
}

export default connect(mapStateToProps, mapDispatchToProps)(CompanyList)